import React, { useState, useEffect } from 'react';
import { get, URL_API } from '@/service/api';
import { Link } from 'react-router-dom';

import models from '@/assets/image/models.jpg';


import '@/assets/style/components/ui/carrossel/post/index.scss';

export const UICarrosselPost = () => {

    //const delay = 10000;
    const [tab] = useState(0);
    const [content, setContent] = useState([]);


    useEffect(()=> {

        const getContent = async () => {
            const data = await get('content/getworks');
            setContent(data?.data);

        }

        getContent();

    }, []);

    // useEffect(()=>{

    //     const timer = setTimeout(()=> {
    //         clearTimeout(timer);
    //         setTab(tab + 1 < content.length ? tab + 1 : 0);
    //     }, delay);

    //     return(()=>{
    //         clearTimeout(timer);
    //     })

    // },[tab, content]);

    return(
        <div className="ui-carrossel-post">
            <div className="cards-list">
                {
                    content.map((item, id) =>{
                        return <div key={`carrossel-post-${id}`} className={`card ${id === tab ? 'current' : ''}`}>
                                    <div className="image">
                                        {
                                            item?.image?.length > 0 && item?.image ?
                                                <img alt="" src={`${URL_API}file/get?file=${item?.image}`} />
                                            :
                                                <img src={models} alt="" />
                                        }
                                    </div>
                                    <div className="content">
                                        <div dangerouslySetInnerHTML={{__html: item?.content }}></div>
                                        {
                                            item?.link && item?.link?.length > 0 ?
                                                <div className='button-more'><Link to={item.link}>Saiba mais</Link></div>
                                            :
                                                ''
                                        }
                                    </div>
                                    
                                </div>
                    })
                }
            </div>
        </div>
    )
}