import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {ic_sort} from 'react-icons-kit/md/ic_sort';
import {ic_cancel} from 'react-icons-kit/md/ic_cancel';

import { DataMainMenu } from '@/service/provider/menu';

import logo from '@/assets/image/logo_full.png';

import '@/assets/style/components/navigation/menu/pages/index.scss';
import Icon from 'react-icons-kit';

export const MenuPages = () => {

    const [showMenu, setShowMenu] = useState(false);
    const menu = useRef();
    const last = useRef();

    useEffect(()=>{

        let list = document.querySelector('#root').querySelectorAll('div');
        list = Array.prototype.filter.call(list, (item) => item.getAttribute('id'));

        document.querySelector('#root').addEventListener('scroll', (e)=>{

            if(e.target.scrollTop >= 100){
                menu.current?.classList?.add('fixed');
            }else{
                menu.current?.classList?.remove('fixed');
            }

            let current = Array.prototype.reduce.call(list, (c, n) => {
                if(n.getBoundingClientRect().y < 200 )
                    return n;
                return c;
            }, -1);

            if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight)
                current = list.at(-1);

                
            last.current = current?.getAttribute('id');

        })

        return(()=>{
            
        })

    }, [])

    return (
        <div ref={menu} className="menu-pages">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className={`area-menu ${showMenu ? 'show' : ''}`}>
                    <Icon onClick={()=>{setShowMenu(!showMenu)}} size={'2em'} className='button-menu' icon={!showMenu ? ic_sort : ic_cancel} />
                <ul className={`menu ${showMenu ? 'show' : ''}`}>
                    {
                        DataMainMenu.map((item, id) => <li key={`main-menu-${id}`}>
                            {
                                /#/gi.test(item.url) ?
                                    <Link key={`link-menu-${id}`} onClick={()=>{setShowMenu(false)}} to={`/${item.url}`}>
                                        {item.name}
                                        {
                                            item?.itens ?
                                                <ul>
                                                    {item?.itens.map((sub, subid) =><li>
                                                        <Link key={`main-menu-${id}-${subid}`} onClick={()=>{setShowMenu(false)}} to={`/${sub.url}`}>
                                                            {sub.name}
                                                        </Link>
                                                    </li>)}
                                                </ul>
                                            :
                                            ''
                                        }
                                    </Link>
                                :
                                    <Link key={`link-menu-${id}`} onClick={()=>{setShowMenu(false)}} to={`/${item.url}`}>
                                        {item.name}
                                        {
                                            item?.itens ?
                                                <ul>
                                                    {item?.itens.map((sub, subid) =><li>
                                                        <Link key={`main-menu-${id}-${subid}`} onClick={()=>{setShowMenu(false)}} to={`/${sub.url}`}>
                                                            {sub.name}
                                                        </Link>
                                                    </li>)}
                                                </ul>
                                            :
                                            ''
                                        }
                                    </Link>
                            }
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
}