import React, { useEffect, useState, useRef, useCallback} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { DataMenuWhatsapp } from '@/service/provider/menu';

import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { jsPDF } from "jspdf";

import { post, get, URL_SENDMAIL } from '@/service/api';

import { useApp } from '@/service/provider/app';

import { Icon } from 'react-icons-kit';
import { whatsapp } from 'react-icons-kit/fa/whatsapp';

import { phoneFormat } from '@/service/utils';

import logo  from '@/assets/image/logo_full.png';

import '@/assets/style/pages/form/index.scss';

export const PageForm = () => {

    const [params] = useSearchParams();
    const [forms, setForms] = useState();

    //const {control, handleSubmit } = useForm({ });
    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const { setIsLoading } = useApp();

    const toast = useRef();

    let tokendata = useRef();

    const autoSave = useRef(false);

    const navigate = useNavigate();


    const getContentSave = useCallback((file)=> {
        console.log('file', file)
        return get('form/getsave', {filename: `${file}.json`});
    }, []);

    const saveContent = useCallback(() => {

        const saveDataContent = async () => {
            const token = params.get('id');
            const objForms = Object.keys(form.getValues());

            if(!token && objForms.length > 0)
                return;

            let file = atob(token);
            file = JSON.parse(file);
            file = btoa(file.email);

            //console.log('Form', form.getValues());
            const data = form.getValues();
            data.filename = file;
            console.log('save');
            await post('form/save', data);
            //console.log(r);
        }

        if(autoSave.current)
            saveDataContent();
        
    },[form, params]);

    const getContent = useCallback(() => { 

        const getData = async () => {
            const data = await post('form/get', {token: params.get('id')});
            
            let formsData = [];
            data.data?.map(item => {
                item.form?.map(question => {
                        formsData.push(question);
                    return question;
                })
                return item;
            });

            const token = params.get('id');

            if(token){
                let file = atob(token);
                file = JSON.parse(file);
                file = btoa(file.email);

                let savedata;

                try{
                    savedata = await getContentSave(file);
                    console.log(savedata);
                    console.log(form);

                    // Object.key(savedata).map(item => {
                    //     form.setValue(item, savedata[item]);
                    //     return item;
                    // })

                    //autoSave.current = true;

                    if(Object.keys(savedata).length === 0 || !savedata)
                        throw new Error('No saved data');
                    
                }catch(ex){
                    console.log('Erro');
                    saveContent();
                }

                
            }

            setForms(formsData);
        }

        getData();

    }, [params, getContentSave, saveContent, form]);

    useEffect(()=>{
        console.log('Execute Save');
        saveContent();
    }, [form, saveContent, control]);

    const getAnswer = (data, item, id) => {
        const typeList = {
            text: () =>{
               //console.log(data);
                return data[`question-${id}`];
            },
            multi: () =>{

                let inhtml = '';
                item.options.map((ele, idx) =>{
                    inhtml += (`<li style="list-style: none; display:flex; align-items: center; margin: 5px auto; ">
                          ${data[`question-${id}`][idx] ? '<div style="display:inline-block; width: 10px; height:10px; background-color:#000; border:1px solid #000; border-radius: 50%; margin-right: 10px;" ></div>' : '<div style="display:inline-block; width: 10px; height:10px; border:1px solid #000; border-radius: 50%; ; margin-right: 10px;" ></div> '} ${ele.text}
                      </li>`);
                    return ele; 
                });
                let html = `<ul style="margin:5px 0; padding:5px 0;width:100%;min-width:570px;">${inhtml}</ul>`;

                return html;

            },
            single: () =>{
                let inhtml = '';
                item.options.map((ele, idx) =>{
                    inhtml += (`<li style="list-style: none; display:flex; align-items: center; margin: 5px auto; ">
                          ${data[`question-${id}`] === ele.value ? '<div style="display:inline-block; width: 10px; height:10px; background-color:#000; border:1px solid #000; border-radius: 50%; margin-right: 10px;" ></div>' : '<div style="display:inline-block; width: 10px; height:10px; border:1px solid #000; border-radius: 50%; ; margin-right: 10px;" ></div> '} ${ele.text}
                      </li>`);
                    return ele; 
                });
                let html = `<ul style="margin:5px 0; padding:5px 0;width:100%;min-width:570px;">${inhtml}</ul>`;

                return html;
            }
        }

        if(typeList[item.type]){
            return typeList[item.type]();
        }else{
            return 'no-data';
        }
    }

    const sendContent = async (data) => {

       // console.log('Send', data);

        const pdf_data = forms.map((item, id)=>{
            return (
                `<div style="width:100%;min-width:570px;">
                    <p><strong>${id + 1}</strong> - ${item.question}</p>
                    <div>${getAnswer(data, item, id)}</div>
                </div>`
            )
        });

        //console.log('PDF Data', pdf_data);

        const pdf = `<html>
                <body style="font-size:12px; width: 570px;padding:5px 5px; background-color:#FFF;">
                <div style="width: 570px; display:block;font-size:12px;margin-top:50px;">
                    <strong>Data proposta</strong>: ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}
                </div>
                <div style="width: 570px; display:block; font-size:12px; margin: 5px 0;">
                    <strong>Cliente</strong>: ${tokendata.current.email}
                </div>
                <div class="border-bottom:2px solid #000;padding-bottom:20px;"></div>
                <h3>Briefing</h3>
                <div id="content" style="width: 530px; display:block;padding:10px 0;">
                    <div style="width: 530px;font-size:12px;">${pdf_data.join('')}</div>
                </div>
                </body>
            </html>`;

           //console.log(pdf);

          // const win = window.open('', 'janela', 'width=300, height=300');
           //win.document.write(pdf);


            const sendEmail = async (s) => {
                const formdata = new FormData();
                formdata.append('files', s);
                formdata.append('subject', 'Briefing');
                formdata.append('altbody', `Nova proposta ${tokendata.current.email}`);
                formdata.append('body', `Chegou uma nova proposta <strong>${tokendata.current.email}</strong><hr /><br />${pdf}`);
                

                const headers = new Headers();

                const params = {
                    method: 'POST',
                    headers: headers,
                    body: formdata

                }

                const send = await fetch(URL_SENDMAIL, params);
                await send.text();


                setIsLoading(false);

               navigate('/form/success');
               autoSave.current = false;

            }

            

            let logo;

            await new Promise((res, err)=>{
                logo = document.createElement('img');
                logo.src = `${URL_SENDMAIL}logo_full.png`;
                logo.addEventListener('load', ()=>{
                    res();
                })
            })

            var doc = new jsPDF({
                orientation: 'l', 
                unit:'px', 
                format:'a4',
                putOnlyUsedFonts: true,
            });

            doc.setDisplayMode(1,'continuous', 'UseOutlines');

            doc.viewerPreferences({'FitWindow': true}, true);
            
            doc.addImage(logo, 'PNG', 30, 20, 200, 21, 'logo', 'NONE');

            doc.html(pdf, {
                margin: [22, 30, 22, 30],
                pagesplit: true,
                width: Number(doc.internal.pageSize.getWidth()),
                height: Number(doc.internal.pageSize.getHeight()),
                callback:()=>{
                    const s = doc.output('blob');
                    doc.save('briefing_resumo');
                   // console.log('send message');
                    sendEmail(s);
                }
            });

           // console.log(pdf);
    }

    const onSubmit = (_data) => {
        console.log(_data);

       setIsLoading(true);

        let erro = false;

        Object.keys(_data).map(item => {
            if(!_data[item]){
                //erro = true;
                document.querySelector(`#${item}`)?.classList?.add('erro');
            }
            return item;
        });

        //console.log('Tem erro', erro);

        if(erro){
            setIsLoading(false);
            toast.current.show({severity:'error', summary: 'Erro', detail:'Preencha todos so campos.', life: 3000});  
        }else{
           sendContent(_data);
        }
    }

    const getFormType = (item, id) =>{

        const typeList = {
            text: () =>{
                return ( <Controller key={`question-${id}`} name={`question-${id}`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                    <textarea onInput={(e) => {
                        
                        field.onChange(e.target.value);
                    }}  id={field.name} {...field} value={field.value}  ></textarea>
                )} />);
            },
            multi: () =>{
                return ( 
                    <>
                    {
                        item?.options?.map((ele, idx) =>{
                                return( 
                                    <Controller key={`question-${id}-${idx}`} name={`question-${id}[${idx}]`} control={control}  render={({ field, fieldState }) => (
                                        <div>
                                            <label htmlFor={`${field.name}-${idx}`}>
                                                <input type="checkbox" onInput={(e) => {
                                                
                                                }}  id={`question-${id}-${idx}`} name={`${field.name}`} {...field} checked={field.checked}  />
                                                <span dangerouslySetInnerHTML={{__html: ele.text}}></span>
                                            </label>
                                        </div>
                                    )} />
                                )
                         })
                     }
                    </>
                );
            },
            single: () =>{
                return ( 
                    <>
                    {
                        item?.options?.map((ele, idx) =>{
                                return( 
                                    <Controller key={`question-${id}-${idx}`} name={`question-${id}`} control={control}  render={({ field, fieldState }) => (
                                        <div>
                                            <label htmlFor={`${field.name}-${idx}`}>
                                                <input type="radio" onInput={(e) => {
                                                    field.onChange(e.target.value);
                                                }}  id={`question-${id}-${idx}`} name={`${field.name}`} {...field} value={ele.value} checked={ele.checked} />
                                                <span dangerouslySetInnerHTML={{__html: ele.text}}></span>
                                            </label>
                                        </div>
                                    )} />
                                )
                         })
                     }
                    </>
                );
            }
        }

        if(typeList[item.type]){
            return typeList[item.type]();
        }else{
            return typeList.text();
        }

    }

    useEffect(()=>{

        try{
            
            tokendata.current = JSON.parse(atob(params.get('id')));
            const current = new Date();
            const limit = new Date(tokendata.current.limit);

           // console.log(current.getTime() , limit.getTime());

            if(current.getTime() < limit.getTime()){
                getContent();
            }else{
                navigate('/form/block')
            }



        }catch(e){
            console.log(e);
        }


    }, [params, getContent, navigate]);

    useEffect(()=>{

        if(!autoSave.current)
            return;

        let update;

        const time = (()=>{
             update = setInterval(()=>{
                saveContent();
                clearInterval(update);
                time();
            }, 10000);
        })

        time();

        return(()=>{
            clearInterval(update);
        })

    }, [saveContent]);

    return (
        <div className="page-form">
            <header>
                <div className='logo'><img src={logo} alt="" /></div>
                <div className="actions"></div>
            </header>
            <section>
                <h1 style={{maxWidth: '1000px', margin:'20px auto'}}>
                    Briefing
                </h1>
            </section>
            <section>
                <div className="questions-area">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {
                            forms?.map((item, id) => {
                                return <div>
                                    <div key={`enum-question-${id}`} className="question" dangerouslySetInnerHTML={{__html: item.question}} >
                                    </div>
                                    {
                                        getFormType(item, id)
                                    }
                                </div>
                            })
                        }
                        {
                           ~~params.get('extra') === 1 ?
                            <div class="extra-info">
                                <p>Gostaria que você me enviasse pelo Whatsapp - <a target="_blank" rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${DataMenuWhatsapp}`}>
                                    <Icon icon={whatsapp} /> {phoneFormat(DataMenuWhatsapp)}
                                </a>:</p>
                                <ul>
                                    <li>3 imagens (pode ser da internet) de paisagens da natureza que você goste</li>
                                    <li>3 imagens (pode ser da internet) de decoração que você goste</li>
                                    <li>3 imagens das peças de roupas que você tem no seu armário que você mais gosta de usar</li>
                                    <li>3 imagens de roupas que você tem no seu armário, mas que não consegue usar por causa da cor, do modelo ou do estilo.</li>
                                </ul>
                            </div>
                            :
                            ''
                        }
                        <div className='area-button'>
                            <button>
                                Enviar formulários
                            </button>
                        </div>
                    </form>
                </div>
            </section>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}