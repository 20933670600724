import React, { useState, createContext, useContext} from "react";

export const AppContext = createContext();

export const AppProvider = (props) => {

    const [ user, setUser ] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    return(
        <AppContext.Provider value = {
            {
                user,
                setUser,
                isLoading,
                setIsLoading
            }
        }>
            {props.children}
        </AppContext.Provider>
    )

}

export const useApp = () => {
    const context = useContext(AppContext);
    const {
        user,
        setUser,
        isLoading,
        setIsLoading
    } = context;

    return {
        user,
        setUser,
        isLoading,
        setIsLoading
    }
}