import React from 'react';

import { Link } from 'react-router-dom';

import { dataContact } from '@/service/api/site';
import { phoneFormat } from '@/service/utils';

import logo from '@/assets/image/logo_full.png';

import '@/assets/style/pages/form/block/index.scss';

export const PageBlock = () => {
    return (
        <div className="page-block">
            <div className="box">
                <div className='mt-4 mb-4'>
                    <img src={logo} alt="" />
                </div>
                    <p>Seu link não é mais válido.</p>
                    <p>
                        Solicite um novo link pelos nossos meios de contato.
                    </p>
                    <div>
                        <div className='email'><a href={`mailto:${dataContact.email}`}>{dataContact.email}</a>,</div>
                        <div>{ phoneFormat(dataContact.whatsapp)}</div>
                    </div>
                <br />
                <div className='mt-4 mb-4 link'>
                    <Link to="/" >Voltar ao site</Link>
                </div>
            </div>
        </div>
    )
}