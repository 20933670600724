import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { PageHome } from '@/pages/home';
import { PagePost } from '@/pages/post';
import { PageAdmin } from '@/pages/admin';
import { PageAdminUser } from '@/pages/admin/user';
import { PageForm } from '@/pages/form';
import { PageSuccess } from '@/pages/form/success';
import { PageBlock } from '@/pages/form/block';

import { useApp } from '@/service/provider/app';


export const MainRouter = (props) => {
    
    const {setIsLoading} = useApp();

    useEffect(()=>{
        setIsLoading(false);
    },[setIsLoading]);

    return (
        <Router >
            <Routes>
                <Route index path="/" element={<PageHome />} />
                <Route path="/post" element={<PagePost />}>
                    <Route path=":id" element={<PagePost />} />
                </Route>

                <Route index path="/admin" element={<PageAdmin />} />
                <Route index path="/admin/user" element={<PageAdminUser />} />

                <Route index path="/form" element={<PageForm />} />
                <Route index path="/form/success" element={<PageSuccess />} />
                <Route index path="/form/block" element={<PageBlock />} />

                <Route path="*" element={<div>Page 404</div>} />
            </Routes>
        </Router>
    )
}