import React, { useEffect, useState, useRef } from 'react';

import { Toast } from 'primereact/toast';

import { useNavigate } from 'react-router-dom';

import { Divider } from 'primereact/divider';

import logo from '@/assets/image/logo_full.png';

import { post } from '@/service/api';

import { useApp } from '@/service/provider/app';

import '@/assets/style/pages/admin/index.scss';

export const PageAdmin = () => {

    const [user, setUser] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();

    const {setIsLoading} = useApp();
    
    const toast = useRef();

    const executeLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = await post('user/login', {user: user, password: password});

        setIsLoading(false);

        if(data.data){
            sessionStorage.setItem('@OLGA_TOKEN', data.data);
            navigate('/admin/user');
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Usuário e/ou Senha inválido', life: 3000});
        }
    }

    useEffect(()=>{

        const userData = sessionStorage.getItem('@OLGA_TOKEN');
        if(userData){
            const isLogin = atob(userData);
            try{
                if(JSON.parse(isLogin)?.login){
                    navigate('/admin/user');
                }
            }catch(ex){}
        }

    },[navigate]);

    return (
        <div className="page-admin">
            <div className='box'>
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <h3>
                    Área admnistrativa
                </h3>
                <form onSubmit={executeLogin} action="">
                    <div className="input-area">
                        <input required placeholder='Usuário' type="text" value={user} onInput={(e) => setUser(e.target.value) } />
                    </div>
                    <div className="input-area">
                        <input required placeholder='Senha' type="password" value={password} onInput={(e) => setPassword(e.target.value) } />
                    </div>
                    <div className="input-area aling-center">
                        <button>
                            Entrar
                        </button>
                    </div>
                </form>
                <Divider />
                <div className="input-area aling-center">
                    <button onClick={()=> navigate('../')}>
                        Voltar ao site
                    </button>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )

}