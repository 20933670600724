import React, { useState, useEffect } from 'react';


import { Icon } from 'react-icons-kit';
import {quotesLeft} from 'react-icons-kit/icomoon/quotesLeft';
import {quotesRight} from 'react-icons-kit/icomoon/quotesRight';
import {bubble} from 'react-icons-kit/icomoon/bubble'
import {ic_east_outline} from 'react-icons-kit/md/ic_east_outline';
import {ic_west_outline} from 'react-icons-kit/md/ic_west_outline';
import {ic_pause_circle_outline_twotone} from 'react-icons-kit/md/ic_pause_circle_outline_twotone';
import {ic_play_circle_outline} from 'react-icons-kit/md/ic_play_circle_outline';

import { get, URL_API } from '@/service/api';


import '@/assets/style/components/ui/carrossel/depoiment/index.scss';

export const UICarrosselDepoiment = () => {

    const delay = 10000;
    const [tab, setTab] = useState(0);
    const [content, setContent] = useState([]);
    const [isPlay, setIsPlay] = useState(true);
    
    const back = () => {
        setTab(tab - 1 < 0 ? content.length - 1 : tab - 1);
    }

    const next = () => {
        setTab(tab + 1 < content.length ? tab + 1 : 0);
    }

    useEffect(()=> {

        const getContent = async () => {
            const data = await get('client/readall');
            setContent(data?.data);

        }

        getContent();

    }, []);

    useEffect(()=>{

        const timer = setTimeout(()=> {
            clearTimeout(timer);
            if(isPlay)
                setTab(tab + 1 < content.length ? tab + 1 : 0);
        }, delay);

        return(()=>{
            clearTimeout(timer);
        })

    },[tab, content, isPlay]);

    return (
        <div className="ui-carrossel-depoiment">
            <div className="depoiment-area">
                <div className="area-people">
                    <div className="people"></div>
                    <div className="people"></div>
                    <div className="people"></div>
                    <div className="people"></div>
                    <div className="people"></div>
                    <div className="people"></div>
                </div>
                <div className="details-cards">

                </div>
                <div className="content">
                    <div className="photo">
                        {
                            content[tab]?.image ?
                                <img alt="" src={`${URL_API}file/get?file=${content[tab]?.image}`} />
                            :
                                <Icon size={'2em'} icon={bubble} />
                        }
                    </div>
                    <div className="text">
                        {content[tab]?.text}
                        <div className="detail-1">
                            <Icon size={'2em'} icon={quotesLeft} />
                        </div>
                        <div className="detail-2">
                            <Icon size={'2em'} icon={quotesRight} />
                        
                        </div>
                    </div>
                    <div className="client">
                        {content[tab]?.client}
                    </div>
                </div>
                {
                    content?.length > 1 ?
                        <div className="navigation">
                            <div onClick={back} className="back">
                                <div className="arrow">
                                    <Icon size={'2em'} icon={ic_west_outline} />
                                </div>
                            </div>
                            <div className="pause">
                                <div className="pause">
                                    {
                                        isPlay ?
                                            <Icon onClick={()=> setIsPlay(false) } size={'2em'} icon={ic_pause_circle_outline_twotone} />
                                        :
                                            <Icon onClick={()=> setIsPlay(true) } size={'2em'} icon={ic_play_circle_outline} />
                                    }
                                </div>
                            </div>
                            <div onClick={next} className="next">
                                <div className="arrow">
                                    <Icon size={'2em'} icon={ic_east_outline} />
                                </div>
                            </div>
                        </div>
                    :
                        ''
                }
            </div>
        </div>
    )
}