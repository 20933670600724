//export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://192.168.1.46/olga/api/' : '/api/';
export const URL_SENDMAIL = (/development/gi).test(process.env.NODE_ENV) ?  'http://192.168.1.46/olga/sendmail/' : '/sendmail/';

//export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/olga/api/' : '/api/';
//export const URL_SENDMAIL = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/olga/sendmail/' : '/sendmail/';

export const URL_API = (/development/gi).test(process.env.NODE_ENV) ?  'http://olgabarra.com/api/' : '/api/';
//export const URL_SENDMAIL = (/development/gi).test(process.env.NODE_ENV) ?  'http://olgabarra.com/sendmail/' : '/sendmail/';
//export const URL_SENDMAIL = 'http://olgabarra.com/sendmail/';

export const get = async (_url, _data = null) => {


    const header = new Headers();
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        header: header
    }

    let querystring = '';

    if(_data){
        const list = Object.keys(_data).map(item => `${item}=${_data[item]}`);
        querystring = '?' + list.join('&');
    }

    const data = await fetch(`${URL_API}${_url}${querystring}`, params);
    
    let json;

    try{
        json = await data?.json();
    }catch(ex){
        json = null;
    }

    return json;

}


export const post = async (_url, _data = null) => {


    const header = new Headers();
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'POST',
        header: header
    }

    if(_data){
        params.body = JSON.stringify(_data);
    }

    const data = await fetch(`${URL_API}${_url}`, params);

    let json;

    try{
        json = await data?.json();
    }catch(ex){
        json = null;
    }

    return json;

}

export const postmail = async ( _data = null) => {


    const header = new Headers();
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'POST',
        header: header
    }

    if(_data){
        params.body = JSON.stringify(_data);
    }

    const data = await fetch(`${URL_SENDMAIL}`, params);

    let json;

    try{
        json = await data?.json();
    }catch(ex){
        json = null;
    }

    return json;

}