export const DataMainMenu = [
    {name: 'Home', url:'#home'},
    {name: 'Olga Barra', url:'#olga'},
    {name: 'Serviços', url:'#service', itens:[
        {name: 'Consultoria de Imagem Corporativa', url:'post/consultoria-corporativa'},
        {name: 'Consultoria de Imagem Pessoal', url:'post/consultoria-imagem'}
    ]},
    {name: 'Depoimentos', url:'#depoimento'},
    {name: 'Contato', url:'#contact'}
];

export const DataMenuServices = [
    {name: 'Consultoria de Imagem Corporativa', url:'post/consultoria-corporativa'},
    {name: 'Consultoria de Imagem Pessoal', url:'post/consultoria-imagem'}
];

export const DataMenuSocialMedia = [
    {name:'linkedin', url:'https://www.linkedin.com/in/olgabarra/'},
    {name:'twitter', url:'https://twitter.com/OlgaFBarra'},
    {name:'instagram', url:'https://www.instagram.com/olgabarra'},
]

export const DataMenuWhatsapp = '31988852593';