import React from 'react';

import { Link } from 'react-router-dom';

import logo from '@/assets/image/logo_full.png';

import '@/assets/style/pages/form/success/index.scss';

export const PageSuccess = () => {
    return (
        <div className="page-success">
            <div className="box">
                <div className='mt-4 mb-4'>
                    <img src={logo} alt="" />
                </div>
                Recebemos seu formulário e em breve entraremos em contato.
                <br />
                <div className='mt-4 mb-4 link'>
                    <Link to="/" >Voltar ao site</Link>
                </div>
            </div>
        </div>
    )
}