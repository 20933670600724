import React, {useEffect, useState, useCallback} from 'react';

import { MenuPages } from '@/components/navigation/menu/pages';
import { UIFooter } from '@/components/ui/footer';
import { useParams, useNavigate } from 'react-router-dom';
import { useApp } from '@/service/provider/app';
import { UIGallery } from '@/components/ui/gallery';
import { get, URL_API } from '@/service/api';

import { ComponentContact} from '@/components/contact';

import '@/assets/style/pages/post/index.scss';

export const PagePost = () => {


    let { id } = useParams();
    let { setIsLoading } = useApp();
    const [pagedata, setPagedata] = useState(null);

    const navigate = useNavigate();

    const openLink = useCallback((link) => {
        navigate(link);
    }, [navigate])

    const getContentTemplate = (data) => {
        const templates = {
            html:()=>{           
                return (<div openLink={openLink} dangerouslySetInnerHTML={{__html: data?.html }}></div>)
            },
            gallery:()=>{
                return( 
                    <div className="box-area">
                    <div className="box-content">
                        <div className="grid">
                            <div className={data?.gallery_format ? data?.gallery_format : 'col-12' }>
                                <div className='area-gallery'>
                                    <UIGallery data={data?.gallery} />
                                </div>
                            </div>
                            <div className={data?.gallery_format ? data?.gallery_format : 'col-12' } dangerouslySetInnerHTML={{__html: data?.gallery_text }}>
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
        }

        if(templates[data.type])
            return templates[data.type]();
    }
    
    useEffect(() => {
        setIsLoading(true);

        const getData = async () => {
            document.querySelector('#root').scrollTo(0, 0);
            const data = await get(`post/${id}`);
            setPagedata(data?.data);
            setIsLoading(false);
        }

        getData();

    }, [id, setIsLoading]);


    useEffect(()=>{
        window.addEventListener("message", (e)=>{

            const actions = {
                link:(url)=>{
                    openLink(url);
                }
            }
            console.log(e.data);
            if(actions[e.data?.action]){
                actions[e.data?.action](e.data?.value);
            }

        })
    },[openLink])

    return (
        <div className="page-post">
            <MenuPages />
            <header>
                <div className="image" style={{'--position': pagedata?.style, backgroundImage:`url(${URL_API}file/get?file=${pagedata?.cover})`}}></div>
                <div className="title" dangerouslySetInnerHTML={{__html: pagedata?.title}}>
                </div>
                {
                    pagedata?.subtitle ?
                      <div className="subtitle">{pagedata?.subtitle}</div>
                    :
                      ''
                }
            </header>
            <div className="content-area">
                {
                    pagedata?.content?.map(item =>  getContentTemplate(item))
                }
            </div>
            <div className="grid" id="contact">
                <ComponentContact />
            </div>
            
            <UIFooter />
        </div>
    )
}