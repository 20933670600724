import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuPages } from '@/components/navigation/menu/pages';
import { UICarrosselPost } from '@/components/ui/carrossel/post';
import { UICarrosselDepoiment } from '@/components/ui/carrossel/depoiment';
import { UIFooter } from '@/components/ui/footer';

import { Icon } from 'react-icons-kit';
import {quotesLeft} from 'react-icons-kit/icomoon/quotesLeft';
import {quotesRight} from 'react-icons-kit/icomoon/quotesRight';

import { ComponentContact} from '@/components/contact';
import { UICarrosselBanner } from '@/components/ui/carrossel/banner';



import '@/assets/style/pages/home/index.scss';

export const PageHome = () => {


    const location = useLocation();


    useEffect(()=>{

        if(location.hash){
            const element = document.querySelector(location.hash);
            element.scrollIntoView({behavior: 'smooth'});
        }

    }, [location]);

    return (
        <div className="page-home" id="home">
            <MenuPages />
            <div className="banner-home">
                    <UICarrosselBanner />
            </div>
            <div className="main-frame">

            <div className="grid" id="olga">
                    <div className='col-12'>
                        <div style={{maxWidth: '1000px', margin:'0 auto'}} className="grid">
                            <div style={{position:'relative'}} className="col-12 md:col-6 area-image-olga">
                                <div className="olga-image"></div>
                            </div>
                            <div className="col-12 md:col-6 olga-text">
                                <div className="title">
                                    OLGA BARRA
                                </div>
                                <div>
                                    <p>Olga Barra é consultora de imagem especializada em Imagem Corporativa.</p>
                                    <p>Iniciou seus estudos em Belo Horizonte, em 2011, com Anita Rezende. Em Atlanta (EUA), foi aluna de Parker Geiger, CEO da Chuva Group, Chuva Beyond e referência na área de Image & Brand Development. Foi também aluna e intérprete de Carla Mathis, grande master mundial em Consultoria de Imagem e autora do livro The Triumph of Individual Style,  considerado a "bíblia" do tema. Desde então, Olga Barra tem refinado sua técnica em dezenas de cursos de especialização e aprimoramento, com certificações nacionais e internacionais.</p>
                                    <p>Mais que especialista, Olga é uma profissional multidisciplinar.</p>
                                    <p>É graduada em Comércio Exterior, formação que lhe permitiu conhecer por dentro o universo corporativo.</p>
                                    <p>Há mais de 30 anos é professora de inglês, e dessa vivência adquiriu a didática necessária para transmitir conhecimento de maneira clara e envolvente.</p>
                                    <p>Bailarina desde sempre, conquistou através da dança a disciplina, a postura e a consciência corporal que tanto ajudam a construir uma imagem positiva de nós mesmos.</p>
                                    <p>Todas essas disciplinas, interligadas, dão a Olga uma visão 360° sobre Imagem e Identidade. Sem deixar de fora nenhum aspecto do processo de construção de imagem, ela auxilia seus clientes a encontrarem de maneira natural e consciente seu estilo próprio e a se comunicarem melhor com o mundo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message-olga">
                    <div className="message">
                        <div className="text">
                            <div>
                                A consultoria de Imagem e Estilo não tem a intenção de rotular ninguém, nem de determinar e/ou limitar as opções e escolhas do cliente! É preciso entender quem ele é, quais os desejos de imagem ele tem e, vamos muito além, decifrar qual conquista amorosa ele está vivendo no momento. O grande estilista mineiro Ronaldo Fraga colocou isso muito bem em sua fala: 
                            </div>
                            <div className="sub-text">
                                “Por trás da escolha da roupa, vai sempre existir uma conquista amorosa. Uma conquista amorosa com o outro, uma conquista amorosa com seu grupo e, o mais difícil, uma conquista amorosa com você mesmo!”
                            </div>
                            <div className="detail-1">
                                <Icon size={'4em'} icon={quotesLeft} />
                            </div>
                            <div className="detail-2">
                                <Icon size={'4em'} icon={quotesRight} />
                            
                            </div>
                        </div>
                        <div className="reference">
                            Olga Barra
                        </div>
                    </div>
                </div>
                
                <div className="grid" id="service" >
                    <div className='col-12'>
                        <div className="services">
                            <div className="title" >
                                SERVIÇOS
                            </div>
                            <div className="subtile">
                                Conheça meu trabalho
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid card-word">
                    <div className='col-12'>
                        <UICarrosselPost />
                    </div>
                </div>

                <div className="grid mb-7" id="depoimento" >
                    <div className='col-12'>
                        <div className="services">
                            <div className="title" >
                                Depoimentos
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid area-depoiment">
                    <div className='col-12'>
                        <UICarrosselDepoiment />
                    </div>
                </div>

                <div className="message-bar">
                    <div className="grid">
                        <div className="col-12">
                            <div className="message">"A IMAGEM CONSTRUÍDA COM INTELIGÊNCIA E CHARME AJUDA NA COMUNICAÇÃO COM O OUTRO. INCORPORA CONFIANÇA. NÃO É UMA QUESTÃO DE SIMPLES VAIDADE, MAS DE SOBREVIVÊNCIA."</div>
                            <div className="reference">COSTANZA PASCOLATO</div>
                        </div>
                    </div>
                </div>

                <div style={{maxWidth: '1000px', margin:'0 auto'}} className="grid" id="contact">
                    <ComponentContact />
                </div>
            </div>
            <UIFooter />
        </div>
    )
}