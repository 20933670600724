export const phoneFormat = (data) => {
    const mask = '(xx) xxxxx xxxx';
    let index = -1;
    let result = mask.split('').map((num, id) =>{
        if(/x/gi.test(num)){
            index++;
            return num.replace('x', data.split('')[index])
        }else{
            return num;
        }
    });

    return result.join('');
}

export const localeData = {
    br:{
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        today: 'Hoje',
        clear: 'Limpar'
    }
}