import React from 'react';

import { useApp } from '@/service/provider/app';

import { useForm, Controller } from 'react-hook-form';

import { postmail } from '@/service/api';

import '@/assets/style/components/contact/index.scss';

export const ComponentContact = () => {


    const defaultValues = {email:'', subject:'select', body:'' };

    const {setIsLoading} = useApp();

    const { control, handleSubmit, reset } = useForm(defaultValues);

    const onSubmit = async (data) => {
        
        setIsLoading(true);

        const senddata = {
            subject: 'Novo contato site',
            altbody: 'Novo contato site',
            email: data.email,
            body: `
                <p>Novo contato de ${data.email}</p>
                <p>Novo contato de ${data.subject}</p>
                <hr>
                <p>${data.body}</p>
            `
        };

        await postmail(senddata);

        reset(defaultValues);

        setIsLoading(false);


    }

    return (
        <div className="component-contact">
            <div className="grid">
            <div className="col-12">
                <div style={{maxWidth: '1000px', margin:'0 auto'}} className="subtitle" >
                    CONTATO
                </div>
            </div>
            <div className='col-12'>
                <div style={{maxWidth: '1000px', width: '100%', margin:'0 auto'}} className="grid">
                    <div className="col-12 md:col-8 md:col-offset-2">
                        <div className="subtitle contact-subtitle">
                                VAMOS ATUALIZAR A SUA IMAGEM?
                            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <Controller name={`email`} control={control}  render={({ field, fieldState }) => (
                                        <input autoComplete='off' onChange={(e)=> field.onChange(e.target.value)} id={field.name} {...field} value={field.value} placeholder='E-mail' type="email" />
                                    )} />
                                </div>
                                <div>
                                    <Controller name={`subject`} control={control}  render={({ field, fieldState }) => (
                                        <select defaultValue="select" onChange={(e)=> field.onChange(e.target.value)} id={field.name} {...field} value={field.value}>
                                            <option value="select" disabled>Selecione o assunto</option>
                                            <option value="serviço">Serviço</option>
                                            <option value="dúvida">Dúvida</option>
                                        </select>
                                    )} />
                                </div>
                                <div>
                                    <Controller name={`body`} control={control}  render={({ field, fieldState }) => (
                                        <textarea onChange={(e)=> field.onChange(e.target.value)} id={field.name} {...field} value={field.value} cols="30" rows="10"></textarea>
                                    )} />
                                </div>
                                <div>
                                    <button >
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}