import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { DataMainMenu, DataMenuServices, DataMenuSocialMedia, DataMenuWhatsapp } from '@/service/provider/menu';

import { Icon } from 'react-icons-kit'
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {twitterSquare} from 'react-icons-kit/fa/twitterSquare';
import {instagram} from 'react-icons-kit/fa/instagram';
import {youtubePlay} from 'react-icons-kit/fa/youtubePlay';
import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare';
import {whatsapp} from 'react-icons-kit/fa/whatsapp'
import {ic_alternate_email} from 'react-icons-kit/md/ic_alternate_email';

import { phoneFormat } from '@/service/utils';
import { dataContact } from '@/service/api/site';

import '@/assets/style/components/ui/footer/index.scss';

export const UIFooter = () => {

    const navigate = useNavigate();

    const iconsList = {
        facebook: facebookSquare,
        twitter: twitterSquare,
        instagram: instagram,
        youtube: youtubePlay,
        linkedin: linkedinSquare
    }

    return (
        <div className="ui-footer">
            <div className="footer">
                <div>
                    <div>
                        <strong>Olga Barra</strong>
                    </div>
                    <div>
                        Consultoria de imagem
                    </div>
                </div>
                <div>
                    <p>
                        <strong>MENU</strong>
                    </p>
                    <ul className="menu">
                    {
                        DataMainMenu.map((item, id) => <li key={`footer-main-menu-${id}`} >
                            {
                                /#/gi.test(item.url) ?
                                    <Link to={`/${item.url}`}>
                                        {item.name}
                                    </Link>
                                :
                                    <Link to={`/${item.url}`}>
                                        {item.name}
                                    </Link>
                            }
                        </li>)
                    }
                    </ul>
                </div>
                <div>
                    <div className="footer-links">
                        <div className="footer-services">
                            <p>
                                <strong>SERVIÇOS</strong>
                            </p>
                            <ul className="menu-services">
                            {
                                DataMenuServices.map((item, id) => <li key={`sercices-menu-${id}`} onClick={()=> navigate(`/${item.url}`)}>
                                    {item.name}
                                </li>)
                            }
                            </ul>   
                        </div>
                        <div className="footer-socialmedia">
                            {
                                DataMenuSocialMedia.map((item, id) => <a key={`social-menu-${id}`} href={item.url} rel="noreferrer" target="_blank">
                                    <Icon icon={iconsList[item.name]} />
                                </a>)
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="links-extra">
                        <div className="whatsapp">
                            <a target="_blank" rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${DataMenuWhatsapp}`}>
                                <Icon icon={whatsapp} /> {phoneFormat(DataMenuWhatsapp)}
                            </a>
                        </div>
                        <div className="email">
                            <a target="_blank" rel='noreferrer' href={`mailto:${dataContact?.email}`}>
                                <Icon icon={ic_alternate_email} /> {dataContact?.email}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}