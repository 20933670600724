import React from 'react';
import ReactDOM from 'react-dom';
import { MainRouter } from './components/navigation/main';
import { AppProvider } from './service/provider/app';
import { UILoading } from './components/ui/loading';

import '@/assets/style/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <MainRouter />
      <UILoading />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
