import React, { useState } from 'react';

import Icon from 'react-icons-kit';
import {ic_clear} from 'react-icons-kit/md/ic_clear'
import {ic_chevron_left} from 'react-icons-kit/md/ic_chevron_left';
import {ic_chevron_right} from 'react-icons-kit/md/ic_chevron_right';

import '@/assets/style/components/ui/gallery/index.scss';

export const UIGallery = ({data}) => {

    const [isShow, setIsShow] = useState(false);
    const [current, setCurrent] = useState(null);
    const [currentID, setCurrentID] = useState(null);

    const openContent = (element, id) => {
        setCurrentID(id);
        setCurrent(element);
        setIsShow(true);
    }

    const closeContent = () => {
        setCurrent(null);
        setIsShow(false);
    }

    const prev = () => {
        const ref = currentID - 1 < 0 ? data.length - 1 : currentID - 1;
        setCurrentID(ref);
        setCurrent(data[ref]);
    }

    const next = () => {
        const ref = currentID + 1 > data.length - 1 ? 0 : currentID + 1;
        setCurrentID(ref);
        setCurrent(data[ref]);
    }

    return (
        <div className="ui-gallery">
            <div className="area-card">
                {
                    data?.map((item, id) => <div key={`item-${id}`} onClick={()=> openContent(item, id) } className="card">
                        <img src={item} alt="" />
                    </div> )
                }
            </div>
            <div className={`area-show ${isShow ? 'active' : ''}`}>
                <div onClick={closeContent} className="button-close">
                    <Icon size={'2em'} icon={ic_clear} />
                </div>
                {
                    data?.length > 1 ?
                        <div className="navigation">
                            <div onClick={prev} className="button-prev">
                                <Icon size={'2em'} icon={ic_chevron_left} />
                            </div>
                            <div onClick={next} className="button-next">
                                <Icon size={'2em'} icon={ic_chevron_right} />
                            </div>
                        </div>
                    :
                        ''
                }
                <div className="content">
                    <img src={current} alt="" />
                </div>
            </div>
        </div>
    )
}