import React, { useState, useEffect } from 'react';
import { get, URL_API } from '@/service/api';


import '@/assets/style/components/ui/carrossel/banner/index.scss';

export const UICarrosselBanner = () => {

    const delay = 10000;
    const [tab, setTab] = useState(0);
    const [content, setContent] = useState([]);


    useEffect(()=> {

        const getContent = async () => {
            const data = await get('content/getbanner');
            setContent(data?.data);

        }

        getContent();

    }, []);

    useEffect(()=>{

        const timer = setTimeout(()=> {
            clearTimeout(timer);
            setTab(tab + 1 < content?.length ? tab + 1 : 0);
        }, delay);

        return(()=>{
            clearTimeout(timer);
        })

    },[tab, content]);

    return(
        <div className="ui-carrossel-banner">
            {
                content?.map((item, id)=> {
                    return (
                        <div className={`content-data card ${id === tab ? 'current' : ''}`}>
                            <div className="bg-color" style={{backgroundImage:`url(${URL_API}file/get?file=${item?.background})`}}></div>
                            <div className={`bg-image ${item?.style}`} style={{backgroundImage:`url(${URL_API}file/get?file=${item?.image})`}} ></div>
                            <div className="cards-list">
                                <div>
                                    <div className="content">
                                        <div dangerouslySetInnerHTML={{__html: item?.content }}></div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                content.length > 1 ?
                    <div className="navigation">
                        {
                            content.map((item, id) => {
                                return <div key={`carrossel-bullet-banner-${id}`} onClick={()=> setTab(id) } className={`button ${id === tab ? 'current' : ''}`}></div>
                            })
                        }
                    </div>
                :
                ''
            }
        </div>
    )
}