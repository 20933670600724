import React, { useEffect, useState, useRef } from "react";

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 

import { Toast } from 'primereact/toast';
 
import { addLocale } from 'primereact/api';
 
import { useNavigate } from 'react-router-dom';

import Icon from 'react-icons-kit';
import {cross} from 'react-icons-kit/icomoon/cross'

import { localeData } from "@/service/utils";

import { get } from '@/service/api';

import '@/assets/style/pages/admin/user/index.scss';

export const PageAdminUser = () => {

    addLocale('pt-BR', localeData.br);

    const { control, handleSubmit, reset } = useForm({ });

    const [link, setLink] = useState();
    const [url, setUrl] = useState();
    const [formsList, setFormsList] = useState([]);

    const toast = useRef();

    const minDay = new Date();
    minDay.setDate(minDay.getDate() + 1);

    const navigate = useNavigate();

    const copy = () => {
        toast.current.show({severity:'success', summary: 'Sucesso', detail:'Link copiado com sucesso.', life: 3000});  
        navigator?.clipboard?.writeText(url);
    }

    const logout = () =>{
        sessionStorage.removeItem('@OLGA_TOKEN');
        navigate('/admin');
    }

    const onSubmit = (_data) => {
        const data = {
            email: _data.email,
            limit: _data.limit,
            forms: []
        }

       // console.log(_data);

        let form_id = null;

        Object.keys(_data).map(item => {

            if((/form_id/gi).test(item)){
                if(_data[item]){
                    data.forms.push(~~item.replace(/\D/gi,''));
                    form_id = ~~item.replace(/\D/gi,'');
                }
            }

            return item;
        })

        //console.log(form_id);

        let extra = formsList.filter(item => ~~item.id === ~~form_id);
        if(extra.length === 1)
            extra = extra[0].extra ? 1 : 0;

        const url = `<a target="_blank" href="../form?id=${btoa(JSON.stringify(data))}&extra=${extra}">Abrir Link</a>`;
        setUrl(`${window.location.origin}/form?id=${btoa(JSON.stringify(data))}&extra=${extra}`);
        setLink(url);
    }

    const clear = (e) => {
        e.preventDefault();
        reset();
        setLink('');
    }

    const getContent = async () => {
        const data = await get('form/getformlist');
        setFormsList(data.data);
    }

    useEffect(()=>{

        const userData = sessionStorage.getItem('@OLGA_TOKEN');
        if(userData){
            const isLogin = atob(userData);
            try{
                if(!JSON.parse(isLogin)?.login){
                    navigate('/admin');
                }else{
                    getContent();
                }
            }catch(ex){
                navigate('/admin');
            }
        }else{
            navigate('/admin');
        }

    },[navigate]);

    return (
        <div class="page-admin-user">
            <header>
                <div onClick={logout} className="button-close">
                    <Icon icon={cross} />
                </div>
            </header>
            <section>
                <div className="content">
                    <h3>
                        Formulários - Criação
                    </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <Controller name="email" control={control}  render={({ field, fieldState }) => (
                                    <InputText type="email" autoComplete="off" style={{width: '100%'}} placeholder="E-mail" required id={field.name} {...field} value={field.value} onChange={(e)=> field.onChange(e.target.value) }  />
                                )} />
                            
                            </div>
                            <div className="col-12 md:col-6">
                                <Controller name="limit" control={control}  render={({ field, fieldState }) => (
                                    <Calendar locale="pt-BR" dateFormat="dd/mm/yy" minDate={minDay} style={{width: '100%'}} placeholder="Data limite" required id={field.name} {...field} value={field.value} onChange={(e)=> field.onChange(e.target.value) }  />
                                )} />
                            </div>
                        </div>
                        <h5>
                            Selecione os Formulários
                        </h5>
                        <div className="grid mt-3">
                            {
                                formsList.map((item) => <div className="col-12">
                                        <Controller name={`form_id_${item.id}`} control={control}  render={({ field, fieldState }) => (
                                    <>
                                        <Checkbox autoComplete="off"  id={field.name} {...field} checked={field.value} onChange={(e)=> field.onChange(e.checked) }  />
                                        <label htmlFor={field.name} className="p-checkbox-label ml-2">{item.name}</label>
                                    </>
                                )} /></div>)
                            }
                        </div>
                        <div className="grid">
                            <div class="col-12">
                                <Button type="submit" label="Gerar" className="mt-4" />
                                <Button onClick={clear} label="Limpar" style={{marginLeft: '15px'}}  className="mt-4 p-button-danger" />
                            </div>
                        </div>
                    </form>
                    {
                        link ?
                            <>
                                <hr />
                                <div className="area-link">
                                    <div dangerouslySetInnerHTML={{__html: link}}> 
                                    </div>
                                    <div onClick={copy}>Copiar Link</div>

                                </div>
                            </>
                        :
                            ''
                    }
                </div>
            </section>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}