import React from 'react';

import { useApp } from '@/service/provider/app';

import img1 from '@/assets/image/olga_loading_1.png';
import img2 from '@/assets/image/olga_loading_2.png';

import '@/assets/style/components/ui/loading/index.scss';

export const UILoading = () => {

    const {isLoading} = useApp();

    return (
        <div style={{display: (isLoading ? 'flex' : 'none')}} className="ui-loading">
            <div className="text">
                <div>
                    <div>
                        <img src={img2} alt="" />
                    </div>
                </div>
                <div><img src={img1} alt="" /></div>
            </div>
        </div>
    )
}